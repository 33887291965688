@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

$namespace: info-card-total;

.#{$namespace} {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem 0.5rem;

  &__text {
    display: inline-block;
    font-family: map-get($font-stack, open-sans);
  }

  &__value-wrapper,
  &__button {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  &__button {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }

  &--condensed {
    .#{$namespace}__text {
      color: $shopkeep-steel;
      font-size: 1.4rem;
      font-weight: 300;
      line-height: (19 / 14);
    }
  }

  &--standalone {
    border-top: 0.1rem solid $shopkeep-navy-light-3;

    .#{$namespace}__text {
      color: $shopkeep-navy;
      font-size: 1.6rem;
      font-weight: 400;
      line-height: (22 / 16);
    }
  }
}
