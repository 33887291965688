.google-pay-button {
  background-image: url('../images/google-pay.png');
  background-repeat: no-repeat;
  background-size: cover;
  border: 0;
  cursor: pointer;
  height: 4rem;
  padding: 0;
  width: 30rem;

  &:focus,
  &:active {
    outline: none;
  }
}
