@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    -webkit-appearance: -apple-pay-button;
    cursor: pointer;
    display: inline-block;
    height: 4rem;
    -apple-pay-button-type: check-out;
    width: 30rem;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    background-color: black;
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: 100% 60%;
    border: 0;
    border-radius: 0.5rem;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    height: 4rem;
    padding: 0;
    width: 30rem;
  }
}
