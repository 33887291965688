@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.field-errors {
  align-items: stretch;
  background-color: $shopkeep-bright-red-light;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style-type: none;
  margin-bottom: 0;
  padding: 0.8rem 1rem;

  &__error {
    color: $shopkeep-bright-red;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.4rem;
    font-weight: 300;
    line-height: (20 / 14);
  }
}
