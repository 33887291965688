@import 'stylesheets/_colors.scss';

.modal {
  &-overlay {
    background-color: rgba(27, 40, 56, 0.4);
  }

  &-content {
    border: none;
    box-shadow: 0.2rem 0.2rem 0.6rem 0.1rem rgba(27, 40, 56, 0.2);
    max-width: 34rem;
    padding: 1.5rem 1.5rem 4rem 1.5rem;
    width: 100%;
  }

  &__close {
    appearance: none;
    background: none;
    border: none;
    color: $shopkeep-navy;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 1.4rem;
    margin: 0 0 3rem auto;
    outline: none;
    padding: 0;
    position: relative;
    width: 1.4rem;

    > svg {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}
