@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.text-card {
  font-family: map-get($font-stack, open-sans);
  text-align: center;

  &__title {
    color: $shopkeep-navy;
    font-size: 2rem;
    font-weight: 400;
    line-height: (27 / 20);
  }

  &__subtitle {
    color: $shopkeep-steel;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: (22 / 16);
    margin-top: 1rem;
  }
}
