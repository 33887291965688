.payment-page {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2rem;
  width: 100%;

  &__cards-container {
    max-width: 32rem;
    width: 100%;
  }
}
