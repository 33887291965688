@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.gratuity-card {
  margin-bottom: 2rem;

  &__form {
    border-bottom: 0.1rem solid $shopkeep-navy-light-3;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    position: relative;
    transition: opacity 600ms;

    &--disabled {
      opacity: 0.3;

      &::before {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 100;
      }
    }
  }

  &__tips-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: -0.5rem;
    margin-top: -0.5rem;
  }

  &__tip {
    margin: 0.5rem;
  }

  &__input-container {
    margin-top: 1rem;
    position: relative;
  }

  &__symbol,
  &__input {
    color: $shopkeep-steel;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.4rem;
    font-weight: 300;
  }

  &__symbol {
    left: 1rem;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__input {
    border: 0.1rem solid $shopkeep-navy-light-3;
    border-radius: 0.5rem;
    box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    display: block;
    height: 3.6rem;
    outline: none;
    padding: 0.8rem 1rem 0.8rem 1.9rem;
    width: 100%;

    &::placeholder {
      color: $shopkeep-steel-light-2;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }

    &[type=number]::-webkit-outer-spin-button,
    &[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
