@import 'stylesheets/_colors.scss';

.gratuity-line {
  &__spinner {
    margin-left: 0.3rem;
    width: 1.2rem;
  }

  &__pencil {
    color: $shopkeep-bright-blue;
    margin-left: 0.6rem;
    position: relative;
    width: 0.9rem;

    &::before {
      content: '';
      display: block;
      height: 0;
      padding-bottom: percentage(14 / 9);
      width: 100%;
    }

    > svg {
      bottom: 0;
      height: 100%;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}
