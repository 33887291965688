@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.loading-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 18rem;

  &__spinner {
    max-width: 8.3rem;
    width: 100%;
  }

  &__text {
    color: $shopkeep-steel;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.6rem;
    font-weight: 300;
    line-height: (22 / 16);
    margin-top: 1rem;
  }
}
