@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.payment-button {
  &__orText {
    color: $shopkeep-steel;
    display: flex;
    flex-direction: row;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.4rem;
    margin-top: 1.3rem;
    text-align: center;
    text-transform: uppercase;

    &::before,
    &::after {
      border-bottom: 0.1rem solid $shopkeep-steel-light-3;
      content: "";
      flex: 1 1;
      margin: auto;
    }

    &::before {
      margin-right: 1rem;
    }

    &::after {
      margin-left: 1rem;
    }
  }
}
