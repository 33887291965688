@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.info-card {
  margin-bottom: 2rem;

  &__store-info-container {
    margin-bottom: 2rem;
  }

  &__logo-container {
    height: 8rem;
    margin: 0 auto 1.8rem auto;
    overflow: hidden;
    width: 8rem;
  }

  &__logo {
    display: block;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &__store-name {
    color: $shopkeep-navy;
    font-family: map-get($font-stack, open-sans);
    font-size: 2rem;
    font-weight: 400;
    line-height: (27 / 20);
    margin-bottom: 0.7rem;
    text-align: center;
  }

  &__address {
    color: $shopkeep-steel;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 300;
    line-height: (19 / 14);
    text-align: center;
  }

  &__totals-container {
    padding: 0 0.5rem;
  }
}
