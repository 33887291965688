@import 'stylesheets/_colors.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.app {
  align-items: center;
  background: $shopkeep-steel-light-3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 100vh;
  width: 100%;

  &__cards-container {
    max-width: 32rem;
    padding-top: 2rem;
    width: 100%;
  }
}
