@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.error-toast {
  align-items: center;
  background: $shopkeep-bright-red;
  display: flex;
  flex-direction: row;
  left: 0;
  min-height: 2.8rem;
  padding: 0.6rem 1rem;
  position: fixed;
  right: 0;
  top: 0;

  &__text {
    color: white;
    display: flex;
    flex-grow: 1;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.2rem;
    font-weight: 400;
    justify-content: center;
    line-height: (16 / 12);
    text-align: center;
  }

  &__dismiss {
    appearance: none;
    background-color: transparent;
    border: none;
    color: $shopkeep-navy;
    cursor: pointer;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.6rem;
    opacity: 0.5;
  }
}
