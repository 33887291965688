@import 'stylesheets/_colors.scss';

.spinner {
  animation: spin 2000ms infinite linear paused;
  position: relative;

  &::before {
    content: '';
    display: block;
    height: 0;
    padding-bottom: 100%;
    width: 100%;
  }

  > svg {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  &--light {
    color: white;
  }

  &--dark {
    color: $shopkeep-steel;
  }

  &--animate {
    animation-play-state: running;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(359deg); }
}
