@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

$namespace: 'button';

.#{$namespace} {
  position: relative;

  &__cta {
    appearance: none;
    border-radius: 0.5rem;
    display: block;
    outline: none;
    transition: opacity 600ms, background 300ms;
    width: 100%;

    &:not(:disabled) {
      cursor: pointer;
    }
  }

  &__text {
    display: block;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.4rem;
    font-weight: 400;
    height: 2rem;
    line-height: (20 / 14);
    opacity: 1;
    text-transform: capitalize;
    transition: opacity 300ms, color 300ms;
  }

  &__spinner {
    left: 50%;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 300ms;
    width: 2.1rem;
  }

  &--pending,
  &--disabled {
    .#{$namespace}__cta {
      opacity: 0.3;
    }
  }

  &--pending {
    .#{$namespace}__text {
      opacity: 0;
    }

    .#{$namespace}__spinner {
      opacity: 1;
    }
  }

  &--main {
    .#{$namespace}__cta {
      background: $shopkeep-bright-blue;
      border: none;
      box-shadow: inset 0 -0.1rem 0 0 rgba(0, 0, 0, 0.2);
      padding: 1rem;

      &:not(:disabled):hover {
        background: darken($shopkeep-bright-blue, 10);
      }
    }

    .#{$namespace}__text {
      color: white;
    }
  }

  &--alt {
    .#{$namespace}__cta {
      background: white;
      border: 0.1rem solid $shopkeep-navy-light-3;
      padding: 0.9rem;

      &:not(:disabled):hover {
        background: darken($shopkeep-navy-light-3, 5);
      }
    }

    .#{$namespace}__text {
      color: $shopkeep-steel;
    }
  }
}
