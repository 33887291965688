@import 'stylesheets/_typography.scss';

.browser-pay-button {
  background-color: #000000;
  border: 0;
  border-radius: 0.5rem;
  color: #FFFFFF;
  cursor: pointer;
  font-family: map-get($font-stack, open-sans);
  font-size: 1.4rem;
  font-weight: 400;
  height: 4rem;
  width: 30rem;
}
