@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.page-footer {
  margin: 2rem 0;

  &__logo-link {
    display: block;
    margin-bottom: 1rem;
    text-decoration: none;
  }

  &__logo-link-copy {
    color: $shopkeep-navy;
    display: block;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: (19 / 14);
    margin-bottom: 0.7rem;
    text-indent: 3rem;
  }

  &__logo-link-image {
    display: block;
    height: auto;
    width: 14.1rem;
  }

  &__legal-links {
    margin: 0;
    padding: 0;
    text-align: center;
  }

  &__legal-list-item {
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__legal-link {
    color: $shopkeep-navy;
    display: inline-block;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.4rem;
    font-weight: 400;
    line-height: (19 / 14);
    text-decoration: underline;
  }
}
