@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.page-not-found {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 2rem;
  max-width: 80rem;

  &__error-code {
    color: $shopkeep-steel-light-1;
    font-family: map-get($font-stack, roboto-slab);
    font-size: 14rem;
    font-weight: 400;
    line-height: 1;
    margin-bottom: 1.5rem;
  }

  &__error-message {
    color: $shopkeep-steel-light-1;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.8rem;
    font-weight: 400;
    text-align: center;
  }
}
