@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.confirm-modal {
  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__title,
  &__body {
    font-family: map-get($font-stack, open-sans);
    max-width: 30rem;
    text-align: center;
  }

  &__title {
    color: $shopkeep-navy;
    font-size: 2rem;
    font-weight: 600;
    line-height: (27 / 20);
    margin-bottom: 2rem;
  }

  &__body {
    color: $shopkeep-steel;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: (19 / 14);
    margin-bottom: 4rem;
  }

  &__button {
    width: percentage(181 / 310);

    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
