@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.card {
  background: white;
  box-shadow: 0 0.2rem 0.2rem 0 rgba(0, 0, 0, 0.15);
  width: 100%;

  &__header-container {
    border-bottom: 0.1rem solid $shopkeep-navy-light-3;
    padding: 1rem;
  }

  &__header {
    color: $shopkeep-navy;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.6rem;
    font-weight: 400;
    line-height: (22 / 16);
  }

  &__content-container {
    padding: 1rem;
  }
}
