@import 'stylesheets/_colors.scss';
@import 'stylesheets/_typography.scss';

.payment-card {
  &__form {
    border-bottom: 0.1rem solid $shopkeep-navy-light-3;
    margin-bottom: 1rem;
    padding-bottom: 2rem;
    position: relative;
    transition: opacity 600ms;

    &--disabled {
      opacity: 0.3;

      &::before {
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 100;
      }
    }
  }

  &__row {
    &--errors {
      margin-top: 0.5rem;
    }

    &--fields {
      align-items: flex-start;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      &:not(:first-child) {
        margin-top: 2rem;
      }
    }
  }

  &__field {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;

    &--card-number {
      width: percentage(228 / 300);
    }

    &--cvv {
      width: percentage(52 / 300);
    }

    &--expiry,
    &--zip {
      width: percentage(140 / 300);
    }
  }

  &__label {
    color: $shopkeep-steel;
    font-family: map-get($font-stack, open-sans);
    font-size: 1.4rem;
    font-weight: 600;
    line-height: (19 / 14);
    margin-bottom: 1rem;
  }

  &__input {
    border: 0.1rem solid $shopkeep-navy-light-3;
    border-radius: 0.5rem;
    box-shadow: inset 0 0.1rem 0.3rem 0 rgba(0, 0, 0, 0.1);
    height: 3.6rem;
    padding: 0.8rem 1rem;
    width: 100%;

    &[type=number] {
      -moz-appearance: textfield;
    }

    &[type=number]::-webkit-outer-spin-button,
    &[type=number]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &--invalid {
      border-color: $shopkeep-bright-red;
    }

    &--standard {
      color: $shopkeep-steel;
      font-family: map-get($font-stack, open-sans);
      font-size: 1.4rem;
      font-weight: 300;
      outline: none;
    }

    &--stripe {
      align-items: stretch;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
